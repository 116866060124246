import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class IndexPrize extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { components } = this.props;
        const winners = components?.winners;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`indexWinners ${components ? '_ready' : ''}`}
                    id="winners"
                >
                    <div className="indexWinners__inner">
                        <div className="indexWinners__title">{winners?.title?.title || 'Победители'}</div>
                        <div className="indexWinners__content">
                            {winners?.winners?.map((item,key) => (
                                <div className="indexWinners__item" key={key}>
                                    <div className="indexWinners__card">
                                        <div className="indexWinners__cardHead">{item.title}</div>
                                        <div className="indexWinners__cardContent">
                                            <div className="indexWinners__cardText">
                                                {item.email}
                                            </div>
                                            <div className="indexWinners__cardText">
                                                {item.phone}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexPrize);

IndexPrize.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
};
